/* @import "~fontsource-poppins/index.css"; */
@import '~fontsource-poppins/400-normal.css';
@import '~fontsource-poppins/500-normal.css';
@import '~fontsource-poppins/600-normal.css';

body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: #f3f6fd;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-font {
    font-family: 'Poppins' !important;
}

.text-xxs {
    font-size: 0.5rem;
}

.top-full {
    top: 100%;
}

.whitespace-nowrap {
    white-space: nowrap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    /* border: 1px solid #5cceff; */
    box-shadow: none;
    -webkit-text-fill-color: #004d71;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
}
