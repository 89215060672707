.react-date-picker {
    display: inline-flex;
    position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
// .react-date-picker--disabled {
//     background-color: #fff;
//     color: #c1c6be;
// }
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
}
.react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54rem * 8 + 0.217rem * 2);
    flex-grow: 1;
    padding: 0.5rem 0;
    box-sizing: content-box;
    font-weight: 500;
}
.react-date-picker__inputGroup__divider {
    color: #004d71;
    padding: 1px 0;
    white-space: pre;
}
.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    color: #004d71;
    box-sizing: content-box;
    -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::placeholder {
    color: #64a8c6;
    opacity: 1; /* Firefox */
}

.react-date-picker__inputGroup__input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #64a8c6;
}

.react-date-picker__inputGroup__input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #64a8c6;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
    background: #c3f0ff;
}
.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}
.react-date-picker__button:enabled {
    cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #00a7e1;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #64a8c6;
}
.react-date-picker__button svg {
    display: inherit;
}
.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}
.react-date-picker__calendar--closed {
    display: none;
}
.react-date-picker__calendar .react-calendar {
    border-width: thin;
}

.react-calendar {
    width: 20rem;
    max-width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #a6e1f3;
    line-height: 1rem;
    color: #004d71;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 2rem;
}
.react-calendar__navigation button {
    min-width: 3rem;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #c3f0ff;
}
.react-calendar__navigation button[disabled] {
    background-color: #e7e7ee;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5rem;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #f44333;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #96b5c3;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1rem 0.5rem;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    background-color: #e7e7ee;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #c3f0ff;
}
.react-calendar__tile--now {
    background: #f0b006;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #f0b006;
}
.react-calendar__tile--hasActive {
    background: #c3f0ff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #c3f0ff;
}
.react-calendar__tile--active {
    background: #00a7e1;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #0072a3;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #c3f0ff;
}
